@media only screen and (max-width: 1665px) {
  .main-section {
    .about-section {
      padding: 43px 18px;
  }
  }
  .nav-list {
    gap: 20px;
  }
  .main-navbar {
    padding: 20px 27px;
  }
  .main-section .work-section .work-title h6 b {
    font-size: 58px;
    line-height: 90.6px;
    }
    .main-section .work-section {
      padding: 45px 15px;
    }
    .main-section .work-section .work-title .my-title h5 {
      font-size: 30px;
    }
    .main-section .work-section .work-title .my-title p {
      font-size: 20px;
    }
    .main-section .work-section .work-title .my-title {
      gap: 4px;
    }
    .main-section .services-section .services-title .services {
      padding-left: 0px;
      padding-right: 0px;
  }
  .main-section .services-section {
    padding: 45px 42px;
  }
   .main-section .our-ceo-section {
  padding: 45px 20px;
   }
   .main-section .our-clients-section {
    padding: 45px 30px;
   }
   .main-section .get-touch-section {
    padding: 45px 22px;
   }
   .main-section .get-touch-section .get-touch-title h6 b {
    font-size: 50px;
   }
.main-section .our-clients-section .our-clients-title h6 b {
  font-size: 57px;
  line-height: 52.6px;
}
.main-about .about-section {
  padding: 45px 15px;
}
.main-about .about-section .col-3 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.main-section .income-brands-section .income-brands-title .income-brands .brands-box {
  padding: 29px 16px;
  gap: 10px;
  width: 100%;
}
.main-section .income-brands-section .income-brands-title h6 b {
  color: rgb(255, 243, 226);
  font-size: 60px;
  line-height: 56.6px;
}
.main-section .income-brands-section .income-brands-title .income-brands {
  display: flex;
  gap: 17px;
}
.main-section .income-brands-section .income-brands-title h6 p {
  font-family: "Nunito", serif;
  font-size: 30px;
}
.main-section .income-brands-section .income-brands-title .income-brands .brands-box h6 {
  font-size: 30px;
}
.main-section .income-brands-section .income-brands-title .income-brands .brands-box p {
  font-size: 22px;
}
.main-section .income-brands-section {
  padding: 45px 2px;
}
.get-touch-section .get-touch{
    display: flex;
    flex-direction: column-reverse;
}
.get-touch-section .get-touch .col-6{
  flex: 0 0 auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
  }
  @media only screen and (max-width: 1144px) {
    .nav-list li {
      font-family: "Lato", sans-serif;
      font-size: 20px;
  }
  .btn-get-connect {
    color: rgb(35, 35, 49);
    background-color: rgb(221, 252, 44);
    padding: 10px 21px;
    border-radius: 36px;
    font-size: 16px;
    border: none;
}
    .main-section .about-section .about-title .my-title{
      gap: 0px;
      margin-top: 16px;
    }
    .main-section .about-section .about-title .my-title h5 {
      font-size: 30px;
      line-height: 42.12px;
    }  
    .main-section .about-section .about-title .my-title p {
      font-size: 18px;
      line-height: 28px;
  }
  .main-section .work-section .work-title .row .col-5 {
    flex: 0 0 auto;
    width: 100%;
    justify-content: center;
  }
  .main-section .work-section .work-title .my-title h5 {
    justify-content: center;
    margin-top: 10px;
    display: flex;
}
   .main-section .work-section .work-title .my-title p {
     text-align: center;
   }
  .main-section .work-section .work-title .row .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-section .services-section .services-title h6 b {
    color: rgb(43, 43, 61);
    font-size: 62px;
    font-weight: 700;
    line-height: 63.6px;
}
.main-section .services-section .services-title .services .services-box h6 {
  font-size: 23px;
}

.main-section .services-section .services-title .services .services-box p {
  font-size: 18px;
  line-height: 27px;
}

.main-section .our-ceo-section .our-ceo-title h6 b {
  font-size: 60px;
  line-height: 64.6px;
}
.main-section .our-ceo-section .our-ceo-title h6 p {
  font-family: "Nunito", serif;
  font-size: 29px;
}
.main-section .our-ceo-section .our-ceo-title .my-title {
  gap: 4px;
}
.main-section .our-ceo-section .our-ceo-title .my-title h6 {
  font-size: 20px;
}
.main-section .our-ceo-section .our-ceo-title .my-title h5 {
  font-size: 27px;
}
.main-section .our-ceo-section .our-ceo-title .my-title p {
  font-size: 19px;
}
  }
  @media only screen and (max-width: 1035px) {
    .main-section .our-ceo-section .our-ceo-title .our-ceo-box img{
      height: 380px;
    }
  }
  @media only screen and (max-width: 967px) {
    .main-about .about-box h6 {
      font-size: 32px;
    }
    .main-about .about-box p {
      font-size: 18px;
    }  
    .main-section{
      .star-connect {
        .star-connect-weraties {
              padding: 30px;
              align-items: center;
              h6{
                font-size: 60px;
                line-height: 46px;
              }
              p{
                font-size: 25px;
              }
            }
            }
    } 
    .main-section .income-brands-section .income-brands-title .income-brands {
      flex-wrap: wrap;
  }
    .main-section .services-section .services-title .services .col-4{
      flex: 0 0 auto;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .main-section .our-clients-section .our-clients-title .our-clients {
      gap: 14px;
      margin-top: 4px;
  }
  .main-section .our-clients-section .our-clients-title .our-clients .clients-box {
    width: 233px;
    height: 114px;
  }
  .main-section .our-clients-section .our-clients-title h6 b {
    font-size: 38px;
  }
  }

  @media only screen and (max-width: 827px) {
    .main-section .client-speak-section {
      padding: 45px 10px;
  }
  .main-section .client-speak-section .client-speak-title h6 b {
    color: rgb(46, 32, 28);
    font-size: 50px;
  }
  .main-section .client-speak-section .client-speak-title .client-speak {
    background-color: rgb(46, 32, 28);
    padding: 30px;
    border-radius: 14px;
    margin-right: 10px;
    margin-left: 10px;
}
    .main-section {
      .our-ceo-section {
        .our-ceo-title{
          .mr-sandeep{
            flex-direction: column-reverse;
          }
          .row{
             .col-7{
              flex: 0 0 auto;
              width: 100%;
             }
             .col-5 {
              flex: 0 0 auto;
              width: 100%;
             }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 750px) {
    .main-section {
      .about-section {
        .about-title{
           .row{
            display: flex;
            flex-direction: column-reverse;
        
              .col-7{
                flex: 0 0 auto;
                width: 100%;
              }
              .col-5{
                flex: 0 0 auto;
                width: 100%;
                justify-content: center;
              }
           }
        }
    }
    }
  }
  @media only screen and (max-width: 700px) {

    .main-section .get-touch-section .get-touch-title .get-touch button {
      font-size: 19px;
    }

    .main-about .about-section .col-3  {
      flex: 0 0 auto;
      width: 100%;
    }
    .main-about .about-section ul li {
          text-align: start;
    }
    .footer-copyright p {
      font-size: 16px;
    }
    .main-section .client-speak-section .client-speak-title .client-speak .testimonial h6 {
      font-size: 20px;
    }
    .main-section .work-section .work-title h6 b {
      font-size: 38px;
      line-height: 39.6px;
  }
  .main-section .work-section .work-title .my-title h5 {
    font-size: 22px;
    flex-wrap: wrap;
}
.main-section .client-speak-section .client-speak-title .client-speak p {
  font-size: 17px;
  line-height: 30px;
}


.main-section .work-section .work-title .my-title p {
  font-size: 16px;
}
  }
  @media only screen and (max-width: 550px) {
    .main-section .work-section .work-title h6 b {
      font-size: 30px;
      line-height: 34.6px;
  }
  .main-section .work-section .work-title h6 p {
    font-size: 29px;
}
  }
  @media only screen and (max-width: 466px) {
    .h-102{
      height: 55px !important;
    }
    .logo-img {
      color: rgb(255, 255, 255);
      font-size: 30px;
      margin: 0px;
    }
    .get-touch-section .get-touch-title .d-flex{
      flex-direction: column;
    }
    .main-section .get-touch-section .get-touch-title h6 b {
      font-size: 36px;
  }
    .main-section .our-ceo-section .our-ceo-title .our-ceo-box img {
      height: 100%;
    }
    .main-section .client-speak-section .client-speak-title .client-speak .testimonial p {
      font-size: 18px;
      line-height: 27.56px;
    }
    .main-section .client-speak-section .client-speak-title h6 b {
      font-size: 33px;
  }
  .main-section .client-speak-section .client-speak-title h6 p {
    font-size: 26px;
  }
    .main-section {
      .star-connect {
        .star-connect-weraties {
          h6 {
            font-size: 44px;
          }
          p{
            font-size: 19px;
            margin: 0px;
          }
        }
      }
    }
    .main-section .about-section .about-title h6 b {
      font-size: 52px;
      line-height: 49.6px;
    }
    .main-section .services-section {
      padding: 45px 16px;
  }
  .main-section .services-section .services-title h6 b {
    font-size: 50px;
    line-height: 45.6px;
}
.main-section .services-section .services-title .services {
  margin-top: 0px;
}
.main-section .our-clients-section .our-clients-title h6 b {
  font-size: 24px;
}
.main-section .our-clients-section .our-clients-title h6 p {
  font-size: 26px;
}
.main-section .our-clients-section .our-clients-title .our-clients .clients-box {
  width: 175px;
  height: 93px;
}
.main-section .our-clients-section {
  padding: 45px 6px;
}
  }

  @media only screen and (max-width: 400px) {
    .main-section .our-clients-section .our-clients-title .our-clients .clients-box {
      width: 140px;
      height: 87px;
  }
  }