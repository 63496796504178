@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
:root {
  --primary: #843DF3;
  --secondary: #A343F1;
  --success: #1F65B6;
  --card: #120923;
  --dark: #0E0E0E;
  --white: #fff;
  --grey: 152, 152, 152;
  --text-color: #BCBCBC;
  --font-family: Londrina Solid;
  --body: #0B0518;
  --section-box: #989898;
  --animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-duration: 1s;
}

.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 75px;
  background-color: rgb(46, 32, 28);
  transition: background-color 0.3s ease;
  z-index: 1000;
  position: fixed;
  width: 100%;
}

.logo-img {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: 700;
  text-align: left;
}

.menu-icon {
  display: none;
  background: none;
  border: none;
  color: rgb(255, 255, 255);
}

.nav-list {
  list-style-type: none;
  display: flex;
  gap: 40px;
  margin: 0;
}

.nav-list li {
  font-family: "Lato", sans-serif;
  font-size: 24px;
}

.nav-list a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.nav-list .active {
  color: rgb(231, 133, 106);
}

.btn-get-connect {
  color: rgb(35, 35, 49);
  background-color: rgb(221, 252, 44);
  padding: 10px 28px;
  border-radius: 36px;
  font-size: 18px;
  border: none;
}

.scrolled {
  background: rgb(20, 31, 57);
}

/* Mobile Styles */
@media (max-width: 972px) {
  .menu-icon {
    display: inline-block;
  }
  .nav-list {
    flex-direction: column;
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: rgb(46, 32, 28);
    width: 100%;
    text-align: center;
    padding: 20px 0;
    gap: 20px;
  }
  .nav-list.open {
    display: flex;
  }
  .nav-list li {
    font-size: 20px;
  }
  .btn-get-connect {
    display: none;
  }
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.main-section {
  background-color: rgb(255, 243, 226);
}
.main-section .star-connect .star-connect-weraties {
  padding: 100px;
  max-width: 100%;
  height: 770px;
  background-image: url("../images/01.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: end;
}
.main-section .star-connect .star-connect-weraties h6 {
  font-family: "Nunito", serif;
  color: rgb(255, 243, 226);
  font-size: 110px;
  font-weight: 700;
  line-height: 120px;
}
.main-section .star-connect .star-connect-weraties p {
  font-family: "Nunito", serif;
  color: rgb(255, 255, 255);
  font-size: 38px;
  font-weight: 700;
  line-height: 51.83px;
}
.main-section .h-102 {
  height: 102px;
}
.main-section .about-section {
  padding: 45px 100px;
}
.main-section .about-section .about-title h6 {
  flex-direction: column-reverse;
  display: flex;
  font-family: "Nunito", serif;
}
.main-section .about-section .about-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55.12px;
  color: rgb(231, 133, 106);
}
.main-section .about-section .about-title h6 b {
  color: rgb(46, 32, 28);
  font-size: 90px;
  font-weight: 700;
  line-height: 119.6px;
}
.main-section .about-section .about-title .my-title {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 6px 12px;
}
.main-section .about-section .about-title .my-title h5 {
  font-size: 36px;
  font-weight: 700;
  line-height: 33.12px;
  color: rgb(231, 133, 106);
  text-align: start;
  font-family: "Nunito", serif;
}
.main-section .about-section .about-title .my-title h5 b {
  color: rgb(46, 32, 28);
}
.main-section .about-section .about-title .my-title p {
  font-family: "Nunito", serif;
  color: rgb(46, 32, 28);
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  text-align: start;
}
.main-section .about-section .about-title .title-box p {
  font-size: 30px;
  font-weight: 500;
  line-height: 27.6px;
  color: rgb(255, 255, 255);
  text-align: start;
}
.main-section .about-section .about-title .title-box p b {
  color: rgb(231, 133, 106);
}
.main-section .work-section {
  padding: 45px 100px;
  background: rgb(46, 32, 28);
}
.main-section .work-section .work-title h6 {
  flex-direction: column-reverse;
  display: flex;
  font-family: "Nunito", serif;
}
.main-section .work-section .work-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55.12px;
  color: rgb(231, 133, 106);
}
.main-section .work-section .work-title h6 b {
  color: rgb(255, 243, 226);
  font-size: 90px;
  font-weight: 700;
  line-height: 119.6px;
}
.main-section .work-section .work-title .my-title {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 6px 12px;
}
.main-section .work-section .work-title .my-title h5 {
  font-size: 36px;
  font-weight: 700;
  line-height: 33.12px;
  color: rgb(255, 255, 255);
  text-align: end;
  font-family: "Nunito", serif;
}
.main-section .work-section .work-title .my-title h5 b {
  color: rgb(231, 133, 106);
}
.main-section .work-section .work-title .my-title p {
  font-family: "Nunito", serif;
  color: rgb(173, 173, 173);
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  text-align: end;
}
.main-section .work-section .work-title .work-box p {
  font-size: 30px;
  font-weight: 500;
  line-height: 27.6px;
  color: rgb(255, 255, 255);
  text-align: start;
}
.main-section .work-section .work-title .work-box p b {
  color: rgb(231, 133, 106);
}
.main-section .services-section {
  padding: 45px 100px;
}
.main-section .services-section .services-title h6 {
  flex-direction: column-reverse;
  display: flex;
  font-family: "Nunito", serif;
}
.main-section .services-section .services-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55.12px;
  color: rgb(231, 133, 106);
}
.main-section .services-section .services-title h6 b {
  color: rgb(43, 43, 61);
  font-size: 90px;
  font-weight: 700;
  line-height: 119.6px;
}
.main-section .services-section .services-title .services {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
}
.main-section .services-section .services-title .services .services-box {
  border: 2px solid rgb(73, 73, 73);
  color: rgb(38, 38, 53);
  padding: 15px 28px;
  border-radius: 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}
.main-section .services-section .services-title .services .services-box .services-img {
  background-color: rgb(244, 228, 204);
  height: 135px;
  width: 199px;
  border-radius: 10px;
  border-radius: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-section .services-section .services-title .services .services-box .services-img img {
  height: 104px;
}
.main-section .services-section .services-title .services .services-box h6 {
  color: rgb(46, 32, 28);
  font-family: "Nunito", serif;
  font-size: 33px;
  font-weight: 600;
  line-height: 33.12px;
  padding: 0px;
  margin-top: 10px;
}
.main-section .services-section .services-title .services .services-box p {
  color: rgb(131, 131, 131);
  font-family: "Nunito", serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
}
.main-section .services-section .services-title .services .services-box button {
  border: 1px solid rgb(231, 133, 106);
  color: rgb(255, 255, 255);
  background-color: rgb(231, 133, 106);
  padding: 10px 22px;
  border-radius: 10px;
}
.main-section .our-ceo-section {
  padding: 45px 100px;
  background-color: rgb(46, 32, 28);
}
.main-section .our-ceo-section .our-ceo-title h6 {
  flex-direction: column-reverse;
  display: flex;
  font-family: "Nunito", serif;
}
.main-section .our-ceo-section .our-ceo-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55.12px;
  color: rgb(231, 133, 106);
}
.main-section .our-ceo-section .our-ceo-title h6 b {
  color: rgb(255, 243, 226);
  font-size: 90px;
  font-weight: 700;
  line-height: 119.6px;
}
.main-section .our-ceo-section .our-ceo-title .my-title {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 6px 12px;
}
.main-section .our-ceo-section .our-ceo-title .my-title h5 {
  font-size: 36px;
  font-weight: 700;
  line-height: 33.12px;
  color: rgb(255, 255, 255);
  text-align: start;
  font-family: "Nunito", serif;
}
.main-section .our-ceo-section .our-ceo-title .my-title h5 b {
  color: rgb(231, 133, 106);
}
.main-section .our-ceo-section .our-ceo-title .my-title h6 {
  color: rgb(218, 218, 218);
  font-family: "Nunito", serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 22.08px;
  text-align: start;
  padding: 0px;
}
.main-section .our-ceo-section .our-ceo-title .my-title p {
  font-family: "Nunito", serif;
  color: rgb(173, 173, 173);
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  text-align: start;
}
.main-section .our-ceo-section .our-ceo-title .our-ceo-box p {
  font-size: 30px;
  font-weight: 500;
  line-height: 27.6px;
  color: rgb(255, 255, 255);
  text-align: start;
}
.main-section .our-ceo-section .our-ceo-title .our-ceo-box p b {
  color: rgb(231, 133, 106);
}
.main-section .our-clients-section {
  padding: 45px 100px;
}
.main-section .our-clients-section .our-clients-title h6 {
  flex-direction: column-reverse;
  display: flex;
  font-family: "Nunito", serif;
}
.main-section .our-clients-section .our-clients-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55.12px;
  color: rgb(231, 133, 106);
}
.main-section .our-clients-section .our-clients-title h6 b {
  color: rgb(43, 43, 61);
  font-size: 90px;
  font-weight: 700;
  line-height: 119.6px;
}
.main-section .our-clients-section .our-clients-title .our-clients {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 70px;
}
.main-section .our-clients-section .our-clients-title .our-clients .clients-box {
  width: 328px;
  height: 132px;
  border-radius: 25px;
  border: 2px solid rgb(198, 198, 198);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
}
.main-section .income-brands-section {
  padding: 45px 100px;
  background-color: rgb(46, 32, 28);
}
.main-section .income-brands-section .income-brands-title h6 {
  flex-direction: column-reverse;
  display: flex;
  font-family: "Nunito", serif;
}
.main-section .income-brands-section .income-brands-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55.12px;
  color: rgb(231, 133, 106);
}
.main-section .income-brands-section .income-brands-title h6 b {
  color: rgb(255, 243, 226);
  font-size: 90px;
  font-weight: 700;
  line-height: 119.6px;
}
.main-section .income-brands-section .income-brands-title .income-brands {
  display: flex;
  gap: 40px;
}
.main-section .income-brands-section .income-brands-title .income-brands .brands-box {
  border-radius: 25px;
  background-color: rgb(255, 243, 226);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 52px 42px;
  gap: 10px;
  width: 100%;
}
.main-section .income-brands-section .income-brands-title .income-brands .brands-box h6 {
  color: rgb(46, 32, 28);
  font-family: "Nunito", serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 46px;
  padding: 0px;
  margin: 0px;
}
.main-section .income-brands-section .income-brands-title .income-brands .brands-box p {
  color: rgb(231, 133, 106);
  font-family: "Nunito", serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 23.92px;
  margin: 0px;
}
.main-section .client-speak-section {
  padding: 45px 100px;
  background-color: rgb(255, 243, 226);
}
.main-section .client-speak-section .client-speak-title .slick-slider {
  display: flex;
  align-items: center;
}
.main-section .client-speak-section .client-speak-title .slick-slider .slick-arrow {
  cursor: pointer;
}
.main-section .client-speak-section .client-speak-title .slick-slider .slick-arrow .icons {
  height: 18px;
}
.main-section .client-speak-section .client-speak-title h6 {
  padding: 15px;
  position: relative;
  padding-top: 0px;
  font-family: "Nunito", serif;
}
.main-section .client-speak-section .client-speak-title h6 p {
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 600;
  color: rgb(231, 133, 106);
}
.main-section .client-speak-section .client-speak-title h6 b {
  color: rgb(46, 32, 28);
  font-size: 90px;
  font-weight: 700;
}
.main-section .client-speak-section .client-speak-title .client-speak {
  background-color: rgb(46, 32, 28);
  padding: 30px;
  border-radius: 14px;
  margin-right: 50px;
  margin-left: 50px;
}
.main-section .client-speak-section .client-speak-title .client-speak .testimonial {
  display: flex;
  gap: 10px;
}
.main-section .client-speak-section .client-speak-title .client-speak .testimonial img {
  height: 54px;
  width: 54px;
  border-radius: 24px;
}
.main-section .client-speak-section .client-speak-title .client-speak .testimonial h6 {
  color: rgb(255, 255, 255);
  font-family: "Nunito", serif;
  font-size: 36px;
  font-weight: 500;
  line-height: 33.12px;
  padding: 0px;
  text-align: start;
}
.main-section .client-speak-section .client-speak-title .client-speak .testimonial p {
  color: rgb(109, 109, 109);
  font-family: "Nunito", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 16.56px;
  text-align: start;
}
.main-section .client-speak-section .client-speak-title .client-speak p {
  color: rgb(173, 173, 173);
  font-family: "Nunito", serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 42px;
  text-align: start;
}
.main-section .client-speak-section .client-speak-title .client-speak .star-icon {
  display: flex;
  gap: 10px;
}
.main-section .client-speak-section .client-speak-title .client-speak .star-icon .icons {
  color: rgb(255, 193, 7);
  font-size: 22px;
}
.main-section .client-speak-section .client-speak-title .slider-box {
  margin-top: 25px;
}
.main-section .get-touch-section {
  padding: 45px 100px;
  background-color: rgb(46, 32, 28);
}
.main-section .get-touch-section .get-touch-title h6 {
  padding: 15px;
  position: relative;
  padding-top: 0px;
  font-family: "Nunito", serif;
}
.main-section .get-touch-section .get-touch-title h6 b {
  color: rgb(255, 243, 226);
  font-size: 95px;
  font-weight: 700;
}
.main-section .get-touch-section .get-touch-title .get-touch h6 {
  color: rgb(255, 255, 255);
  font-family: "Nunito", serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 27.6px;
  text-align: start;
}
.main-section .get-touch-section .get-touch-title .get-touch input {
  background-color: rgb(61, 41, 35);
  border: none;
  padding: 15px 10px;
  color: rgb(231, 231, 231);
  font-family: "Nunito", serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 20.24px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.main-section .get-touch-section .get-touch-title .get-touch textarea {
  background-color: rgb(61, 41, 35);
  border: none;
  padding: 10px;
  color: rgb(231, 231, 231);
  font-family: "Nunito", serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 20.24px;
  width: 100%;
  border-radius: 10px;
  margin-top: 14px;
}
.main-section .get-touch-section .get-touch-title .get-touch button {
  background-color: rgb(231, 133, 106);
  color: rgb(231, 231, 231);
  font-family: "Nunito", serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 25.76px;
  padding: 14px 22px;
  border: none;
  margin-top: 14px;
  border-radius: 10px;
}

.main-about .about-section {
  padding: 45px 100px;
  background-color: rgb(255, 243, 226);
}
.main-about .about-box h6 {
  font-family: "Nunito", serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 42.32px;
  color: rgb(46, 32, 28);
  text-align: start;
}
.main-about .about-box h6 b {
  color: rgb(231, 133, 106);
}
.main-about .about-box p {
  color: rgb(46, 32, 28);
  font-family: "Nunito", serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 42px;
  text-align: start;
}
.main-about ul li {
  font-family: "Nunito", serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 35.76px;
  color: rgb(46, 32, 28);
  list-style-type: none;
}
.main-about ul li b {
  color: rgb(231, 133, 106);
}

.footer-copyright {
  background-color: rgb(255, 243, 226);
}
.footer-copyright p {
  margin: 0px;
  color: rgb(46, 32, 28);
  font-family: "Nunito", serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 25.76px;
  border-top: 1px solid rgb(139, 139, 139);
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1665px) {
  .main-section .about-section {
    padding: 43px 18px;
  }
  .nav-list {
    gap: 20px;
  }
  .main-navbar {
    padding: 20px 27px;
  }
  .main-section .work-section .work-title h6 b {
    font-size: 58px;
    line-height: 90.6px;
  }
  .main-section .work-section {
    padding: 45px 15px;
  }
  .main-section .work-section .work-title .my-title h5 {
    font-size: 30px;
  }
  .main-section .work-section .work-title .my-title p {
    font-size: 20px;
  }
  .main-section .work-section .work-title .my-title {
    gap: 4px;
  }
  .main-section .services-section .services-title .services {
    padding-left: 0px;
    padding-right: 0px;
  }
  .main-section .services-section {
    padding: 45px 42px;
  }
  .main-section .our-ceo-section {
    padding: 45px 20px;
  }
  .main-section .our-clients-section {
    padding: 45px 30px;
  }
  .main-section .get-touch-section {
    padding: 45px 22px;
  }
  .main-section .get-touch-section .get-touch-title h6 b {
    font-size: 50px;
  }
  .main-section .our-clients-section .our-clients-title h6 b {
    font-size: 57px;
    line-height: 52.6px;
  }
  .main-about .about-section {
    padding: 45px 15px;
  }
  .main-about .about-section .col-3 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .main-section .income-brands-section .income-brands-title .income-brands .brands-box {
    padding: 29px 16px;
    gap: 10px;
    width: 100%;
  }
  .main-section .income-brands-section .income-brands-title h6 b {
    color: rgb(255, 243, 226);
    font-size: 60px;
    line-height: 56.6px;
  }
  .main-section .income-brands-section .income-brands-title .income-brands {
    display: flex;
    gap: 17px;
  }
  .main-section .income-brands-section .income-brands-title h6 p {
    font-family: "Nunito", serif;
    font-size: 30px;
  }
  .main-section .income-brands-section .income-brands-title .income-brands .brands-box h6 {
    font-size: 30px;
  }
  .main-section .income-brands-section .income-brands-title .income-brands .brands-box p {
    font-size: 22px;
  }
  .main-section .income-brands-section {
    padding: 45px 2px;
  }
  .get-touch-section .get-touch {
    display: flex;
    flex-direction: column-reverse;
  }
  .get-touch-section .get-touch .col-6 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1144px) {
  .nav-list li {
    font-family: "Lato", sans-serif;
    font-size: 20px;
  }
  .btn-get-connect {
    color: rgb(35, 35, 49);
    background-color: rgb(221, 252, 44);
    padding: 10px 21px;
    border-radius: 36px;
    font-size: 16px;
    border: none;
  }
  .main-section .about-section .about-title .my-title {
    gap: 0px;
    margin-top: 16px;
  }
  .main-section .about-section .about-title .my-title h5 {
    font-size: 30px;
    line-height: 42.12px;
  }
  .main-section .about-section .about-title .my-title p {
    font-size: 18px;
    line-height: 28px;
  }
  .main-section .work-section .work-title .row .col-5 {
    flex: 0 0 auto;
    width: 100%;
    justify-content: center;
  }
  .main-section .work-section .work-title .my-title h5 {
    justify-content: center;
    margin-top: 10px;
    display: flex;
  }
  .main-section .work-section .work-title .my-title p {
    text-align: center;
  }
  .main-section .work-section .work-title .row .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-section .services-section .services-title h6 b {
    color: rgb(43, 43, 61);
    font-size: 62px;
    font-weight: 700;
    line-height: 63.6px;
  }
  .main-section .services-section .services-title .services .services-box h6 {
    font-size: 23px;
  }
  .main-section .services-section .services-title .services .services-box p {
    font-size: 18px;
    line-height: 27px;
  }
  .main-section .our-ceo-section .our-ceo-title h6 b {
    font-size: 60px;
    line-height: 64.6px;
  }
  .main-section .our-ceo-section .our-ceo-title h6 p {
    font-family: "Nunito", serif;
    font-size: 29px;
  }
  .main-section .our-ceo-section .our-ceo-title .my-title {
    gap: 4px;
  }
  .main-section .our-ceo-section .our-ceo-title .my-title h6 {
    font-size: 20px;
  }
  .main-section .our-ceo-section .our-ceo-title .my-title h5 {
    font-size: 27px;
  }
  .main-section .our-ceo-section .our-ceo-title .my-title p {
    font-size: 19px;
  }
}
@media only screen and (max-width: 1035px) {
  .main-section .our-ceo-section .our-ceo-title .our-ceo-box img {
    height: 380px;
  }
}
@media only screen and (max-width: 967px) {
  .main-about .about-box h6 {
    font-size: 32px;
  }
  .main-about .about-box p {
    font-size: 18px;
  }
  .main-section .star-connect .star-connect-weraties {
    padding: 30px;
    align-items: center;
  }
  .main-section .star-connect .star-connect-weraties h6 {
    font-size: 60px;
    line-height: 46px;
  }
  .main-section .star-connect .star-connect-weraties p {
    font-size: 25px;
  }
  .main-section .income-brands-section .income-brands-title .income-brands {
    flex-wrap: wrap;
  }
  .main-section .services-section .services-title .services .col-4 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .main-section .our-clients-section .our-clients-title .our-clients {
    gap: 14px;
    margin-top: 4px;
  }
  .main-section .our-clients-section .our-clients-title .our-clients .clients-box {
    width: 233px;
    height: 114px;
  }
  .main-section .our-clients-section .our-clients-title h6 b {
    font-size: 38px;
  }
}
@media only screen and (max-width: 827px) {
  .main-section .client-speak-section {
    padding: 45px 10px;
  }
  .main-section .client-speak-section .client-speak-title h6 b {
    color: rgb(46, 32, 28);
    font-size: 50px;
  }
  .main-section .client-speak-section .client-speak-title .client-speak {
    background-color: rgb(46, 32, 28);
    padding: 30px;
    border-radius: 14px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .main-section .our-ceo-section .our-ceo-title .mr-sandeep {
    flex-direction: column-reverse;
  }
  .main-section .our-ceo-section .our-ceo-title .row .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-section .our-ceo-section .our-ceo-title .row .col-5 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .main-section .about-section .about-title .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .main-section .about-section .about-title .row .col-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-section .about-section .about-title .row .col-5 {
    flex: 0 0 auto;
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) {
  .main-section .get-touch-section .get-touch-title .get-touch button {
    font-size: 19px;
  }
  .main-about .about-section .col-3 {
    flex: 0 0 auto;
    width: 100%;
  }
  .main-about .about-section ul li {
    text-align: start;
  }
  .footer-copyright p {
    font-size: 16px;
  }
  .main-section .client-speak-section .client-speak-title .client-speak .testimonial h6 {
    font-size: 20px;
  }
  .main-section .work-section .work-title h6 b {
    font-size: 38px;
    line-height: 39.6px;
  }
  .main-section .work-section .work-title .my-title h5 {
    font-size: 22px;
    flex-wrap: wrap;
  }
  .main-section .client-speak-section .client-speak-title .client-speak p {
    font-size: 17px;
    line-height: 30px;
  }
  .main-section .work-section .work-title .my-title p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 550px) {
  .main-section .work-section .work-title h6 b {
    font-size: 30px;
    line-height: 34.6px;
  }
  .main-section .work-section .work-title h6 p {
    font-size: 29px;
  }
}
@media only screen and (max-width: 466px) {
  .h-102 {
    height: 55px !important;
  }
  .logo-img {
    color: rgb(255, 255, 255);
    font-size: 30px;
    margin: 0px;
  }
  .get-touch-section .get-touch-title .d-flex {
    flex-direction: column;
  }
  .main-section .get-touch-section .get-touch-title h6 b {
    font-size: 36px;
  }
  .main-section .our-ceo-section .our-ceo-title .our-ceo-box img {
    height: 100%;
  }
  .main-section .client-speak-section .client-speak-title .client-speak .testimonial p {
    font-size: 18px;
    line-height: 27.56px;
  }
  .main-section .client-speak-section .client-speak-title h6 b {
    font-size: 33px;
  }
  .main-section .client-speak-section .client-speak-title h6 p {
    font-size: 26px;
  }
  .main-section .star-connect .star-connect-weraties h6 {
    font-size: 44px;
  }
  .main-section .star-connect .star-connect-weraties p {
    font-size: 19px;
    margin: 0px;
  }
  .main-section .about-section .about-title h6 b {
    font-size: 52px;
    line-height: 49.6px;
  }
  .main-section .services-section {
    padding: 45px 16px;
  }
  .main-section .services-section .services-title h6 b {
    font-size: 50px;
    line-height: 45.6px;
  }
  .main-section .services-section .services-title .services {
    margin-top: 0px;
  }
  .main-section .our-clients-section .our-clients-title h6 b {
    font-size: 24px;
  }
  .main-section .our-clients-section .our-clients-title h6 p {
    font-size: 26px;
  }
  .main-section .our-clients-section .our-clients-title .our-clients .clients-box {
    width: 175px;
    height: 93px;
  }
  .main-section .our-clients-section {
    padding: 45px 6px;
  }
}
@media only screen and (max-width: 400px) {
  .main-section .our-clients-section .our-clients-title .our-clients .clients-box {
    width: 140px;
    height: 87px;
  }
}/*# sourceMappingURL=style.css.map */