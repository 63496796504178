.main-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 75px;
  background-color: rgba(46, 32, 28, 1);
  transition: background-color 0.3s ease;
  z-index: 1000;
  position: fixed;
  width: 100%
}

.logo-img {
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-weight: 700;
  text-align: left;
}

.menu-icon {
  display: none;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 1);
}

.nav-list {
  list-style-type: none;
  display: flex;
  gap: 40px;
  margin: 0;
}

.nav-list li {
  font-family: "Lato", sans-serif;
  font-size: 24px;
}

.nav-list a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.nav-list .active {
  color: rgba(231, 133, 106, 1);
}

.btn-get-connect {
  color: rgba(35, 35, 49, 1);
  background-color: rgba(221, 252, 44, 1);
  padding: 10px 28px;
  border-radius: 36px;
  font-size: 18px;
  border: none;
}

.scrolled {
  background: rgba(20, 31, 57, 1);
}

/* Mobile Styles */
@media (max-width: 972px) {
  .menu-icon {
    display: inline-block;
  }

  .nav-list {
    flex-direction: column;
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: rgba(46, 32, 28, 1);
    width: 100%;
    text-align: center;
    padding: 20px 0;
    gap: 20px;
  }

  .nav-list.open {
    display: flex;
  }

  .nav-list li {
    font-size: 20px;
  }

  .btn-get-connect {
    display: none;
  }
}
