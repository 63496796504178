*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.main-section{
    background-color: rgba(255, 243, 226, 1);
    .star-connect{
        .star-connect-weraties{
            padding: 100px;
            max-width: 100%;
            height: 770px;
            background-image: url("../images/01.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            align-items: end;
    
            h6{
                 font-family: "Nunito", serif;
                color: rgba(255, 243, 226, 1);
                font-size: 110px;
                font-weight: 700;
                line-height: 120px;

            }
            p{
                 font-family: "Nunito", serif;
                color: rgba(255, 255, 255, 1);
                font-size: 38px;
                font-weight: 700;
                line-height: 51.83px;
            }
        }  
    }


    .h-102{
        height: 102px;
    }

     .about-section{
        padding: 45px 100px;
        .about-title{
            h6{
                    flex-direction: column-reverse;
                    display: flex;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 55.12px;
                    color: rgba(231, 133, 106, 1);
                    
                }
                b{
                    color: rgba(46, 32, 28, 1);

                    font-size: 90px;
                    font-weight: 700;
                    line-height: 119.6px;
                              
                }
            }
            .my-title{
                display: flex;
                flex-direction: column;
                gap: 32px;
                padding: 6px 12px;
                
                h5{
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 33.12px;
                    color: rgba(231, 133, 106, 1);
                    text-align: start;
                    font-family: "Nunito", serif;
                    b{
                        color: rgba(46, 32, 28, 1);
                    }
                }
                p{
                    font-family: "Nunito", serif;
                    color: rgba(46, 32, 28, 1);
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 42px;
                    text-align: start;
                }
            }
            .title-box{
                p{
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 27.6px;
                    color: rgba(255, 255, 255, 1);
                    text-align: start;
                    b{
                        color: rgba(231, 133, 106, 1);
                    }
                }
            }
        } 
     }
     .work-section{
        padding: 45px 100px;
        background: rgba(46, 32, 28, 1);

        .work-title{
            h6{
                    flex-direction: column-reverse;
    display: flex;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 55.12px;
                    color: rgba(231, 133, 106, 1);
                    
                }
                b{
                    color: rgba(255, 243, 226, 1);
                  
                    font-size: 90px;
                    font-weight: 700;
                    line-height: 119.6px;
                          
                }
            }
            .my-title{
                display: flex;
                flex-direction: column;
                gap: 32px;
                padding: 6px 12px;
                
                h5{
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 33.12px;
                    color: rgba(255, 255, 255, 1);
                    text-align: end;
                    font-family: "Nunito", serif;
                    b{
                        color: rgba(231, 133, 106, 1);
                    }
                }
                p{
                    font-family: "Nunito", serif;
                    color: rgba(173, 173, 173, 1);
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 42px;
                    text-align: end;
                }
            }
            .work-box{
                p{
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 27.6px;
                    color: rgba(255, 255, 255, 1);
                    text-align: start;
                    b{
                        color: rgba(231, 133, 106, 1);
                    }
                }
            }
        } 
     }
     .services-section{
        padding: 45px 100px;
        
        .services-title{
            h6{
                    flex-direction: column-reverse;
    display: flex;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 55.12px;
                    color: rgba(231, 133, 106, 1);
                    
                }
                b{
                    color: rgba(43, 43, 61, 1);
                  
                    font-size: 90px;
                    font-weight: 700;
                    line-height: 119.6px;
                         
                }
            }
            .services{
                padding-left: 100px;
                padding-right: 100px;
                margin-top: 50px;
                
                .services-box{
                    border: 2px solid rgb(73, 73, 73);
                    color: rgb(38, 38, 53);
                    padding: 15px 28px;
                    border-radius: 18px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 14px;

                    .services-img{
                        background-color: rgb(244, 228, 204);
                        height: 135px;
                        width: 199px;
                        border-radius: 10px;
                        border-radius: 83px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            height: 104px;
                        }
                    }
                    
                    h6{
                       color: rgba(46, 32, 28, 1);
                        font-family: "Nunito", serif;
                        font-size: 33px;
                       font-weight: 600;
                       line-height: 33.12px;
                       padding: 0px;
                       margin-top: 10px;
                    }
                    p{
                      color: rgba(131, 131, 131, 1);
                      font-family: "Nunito", serif;
                      font-size: 22px;
                      font-weight: 400;
                      line-height: 42px;
                    }
                    button{
                        border: 1px solid rgba(231, 133, 106, 1);
                        color: rgba(255, 255, 255, 1);
                        background-color: rgba(231, 133, 106, 1);
                        padding: 10px 22px;
                        border-radius: 10px;
                    }
                }
            }
        } 
     }
     .our-ceo-section{
        padding: 45px 100px;
        background-color: rgba(46, 32, 28, 1);
        .our-ceo-title{
            h6{
                    flex-direction: column-reverse;
    display: flex;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 55.12px;
                    color: rgba(231, 133, 106, 1);
                    
                }
                b{
                    color: rgba(255, 243, 226, 1);
                  
                    font-size: 90px;
                    font-weight: 700;
                    line-height: 119.6px;
                              
                }
            }
            .my-title{
                display: flex;
                flex-direction: column;
                gap: 32px;
                padding: 6px 12px;
                
                h5{
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 33.12px;
                    color: rgba(255, 255, 255, 1);
                    text-align: start;
                    font-family: "Nunito", serif;
                    b{
                        color: rgba(231, 133, 106, 1);
                    }
                }
                h6{
                    color: rgba(218, 218, 218, 1);
                    font-family: "Nunito", serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 22.08px;
                    text-align: start;
                    padding: 0px;
                }
                p{
                    font-family: "Nunito", serif;
                    color: rgba(173, 173, 173, 1);
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 42px;
                    text-align: start;
                }
            }
            .our-ceo-box{
                p{
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 27.6px;
                    color: rgba(255, 255, 255, 1);
                    text-align: start;
                    b{
                        color: rgba(231, 133, 106, 1);
                    }
                }
            }
        } 
     }
     .our-clients-section{
        padding: 45px 100px;
        .our-clients-title{
            h6{
                    flex-direction: column-reverse;
    display: flex;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 55.12px;
                    color: rgba(231, 133, 106, 1);
                    
                }
                b{
                    color: rgb(43, 43, 61);
                  
                    font-size: 90px;
                    font-weight: 700;
                    line-height: 119.6px;
                  
                }
            }
            .our-clients{
                display: flex;
                gap: 40px;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 70px;


                .clients-box{
                    width: 328px;
                    height: 132px;
                    border-radius: 25px;
                    border:2px solid rgb(198, 198, 198);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 22px;
                }
            }
        } 
     }
     .income-brands-section{
        padding: 45px 100px;
        background-color: rgba(46, 32, 28, 1);
        .income-brands-title{
            h6{
                    flex-direction: column-reverse;
    display: flex;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 55.12px;
                    color: rgba(231, 133, 106, 1);
                    
                }
                b{
                    color: rgba(255, 243, 226, 1);
                  
                    font-size: 90px;
                    font-weight: 700;
                    line-height: 119.6px;
                  
                }
            }
            .income-brands{
                display: flex;
                gap: 40px;

                .brands-box{
                    border-radius: 25px;
                    background-color: rgba(255, 243, 226, 1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 52px 42px;
                    gap: 10px;
                    width: 100%;

                    h6{
                        color: rgba(46, 32, 28, 1);
                        font-family: "Nunito", serif;
                        font-size: 42px;
                        font-weight: 600;
                        line-height: 46px;
                        padding: 0px;
                        margin: 0px;
                    }
                    p{
                        color: rgba(231, 133, 106, 1);
                        font-family: "Nunito", serif;
                        font-size: 26px;
                        font-weight: 600;
                        line-height: 23.92px;
                        margin: 0px;
                    }
                }
            }
        } 
     }
     .client-speak-section{
        padding: 45px 100px;
        background-color: rgba(255, 243, 226, 1);
        .client-speak-title{
            .slick-slider {
                display: flex;
                align-items: center;
                .slick-arrow{
                    cursor: pointer;
                    .icons{
                        height: 18px;
                    }
                }
            }
            h6{
                padding: 15px;
                position: relative;
                padding-top: 0px;
                font-family: "Nunito", serif;
                p{
                    font-family: "Nunito", serif;
                    font-size: 36px;
                    font-weight: 600;
                    color: rgba(231, 133, 106, 1);
                }
                b{
                    color: rgba(46, 32, 28, 1);
                    font-size: 90px;
                    font-weight: 700;
                }
            }
            .client-speak{
                background-color: rgb(46, 32, 28);
                padding: 30px;
                border-radius: 14px;
                margin-right: 50px;
                margin-left: 50px;

                .testimonial{
                   display: flex;
                   gap: 10px;
                   img{
                    height: 54px;
                    width: 54px;
                    border-radius: 24px;
                   }
                   h6{
                     color: rgba(255, 255, 255, 1);
                      font-family: "Nunito", serif;
                     font-size: 36px;
                     font-weight: 500;
                     line-height: 33.12px;
                     padding: 0px;
                     text-align: start;
                   }
                   p{
                    color: rgba(109, 109, 109, 1);
                    font-family: "Nunito", serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 16.56px;
                    text-align: start;
                   }
                } 
                p{
                    color: rgba(173, 173, 173, 1);
                    font-family: "Nunito", serif;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 42px;
                    text-align: start;
                }
                .star-icon{
                    display: flex;
                    gap: 10px;
                    .icons{
                        color: rgb(255,193,7);
                        font-size: 22px;
                    }
                }
            }
            .slider-box{
                margin-top: 25px;
            }
        } 
     }
     .get-touch-section{
        padding: 45px 100px;
        background-color: rgba(46, 32, 28, 1);
        .get-touch-title{
            h6{
                padding: 15px;
                position: relative;
                padding-top: 0px;
                font-family: "Nunito", serif;
                b{
                    color: rgba(255, 243, 226, 1);
                    font-size: 95px;
                    font-weight: 700;
                }
            }
            .get-touch{
                  h6{
                    color: rgba(255, 255, 255, 1);
                    font-family: "Nunito", serif;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 27.6px;
                    text-align: start;
                  }
                  input{
                    background-color: rgb(61, 41, 35);
                    border: none;
                    padding: 15px 10px;
                    color: rgb(231, 231, 231);
                    font-family: "Nunito", serif;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 20.24px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                  }
                  textarea{
                    background-color: rgba(61, 41, 35, 1);
                    border: none;
                    padding: 10px;
                    color: rgba(231, 231, 231, 1);
                    font-family: "Nunito", serif;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 20.24px;
                    width: 100%;
                    border-radius: 10px;
                    margin-top: 14px;
                  }
                  button{
                    background-color: rgb(231, 133, 106);
                    color: rgb(231, 231, 231);
                    font-family: "Nunito", serif;
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 25.76px;
                    padding: 14px 22px;
                    border: none;
                    margin-top: 14px;
                    border-radius: 10px;
                  }
            }
        } 
     }
  
}