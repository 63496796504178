.main-about{
    .about-section{
        padding: 45px 100px;
        background-color: rgba(255, 243, 226, 1);
    }
    .about-box{
        h6{
            font-family: "Nunito", serif;
            font-size: 46px;
            font-weight: 700;
            line-height: 42.32px;
            color: rgba(46, 32, 28, 1);
            text-align: start;
            b{
                color: rgba(231, 133, 106, 1);
            }
        }
        p{
            color: rgba(46, 32, 28, 1);
            font-family: "Nunito", serif;
            font-size: 22px;
            font-weight: 500;
            line-height: 42px;
            text-align: start;
        }
    }
    ul{
        li{
            font-family: "Nunito", serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 35.76px;
            color: rgb(46, 32, 28);
            list-style-type: none;
            b{
                color: rgba(231, 133, 106, 1);
            }
        }
    }
  
}
.footer-copyright{
    background-color: rgba(255, 243, 226, 1);
    p{
        margin: 0px;
        color: rgba(46, 32, 28, 1);
        font-family: "Nunito", serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 25.76px;
        border-top: 1px solid rgba(139, 139, 139, 1);
        padding-top: 20px;
        padding-bottom: 20px;    
    }
}